import React, { Component } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
  Col,
} from "react-bootstrap";
import {
  getAllDocumentType,
  getAllProcessType,
  putProcessInsertProcessType,
  postProcessUpdateProcessType,
} from "../../../services/RequestService";
import "../../../pages/settings/process/SettingsProcessPage.css";
import { alertActions } from "../../../store/actions/AlertActions";
import { connect } from "react-redux";

class ModalProcessType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processType: props.name === null ? "" : props.name,
      isProcessTypeValid: props.name === null ? undefined : true,
      isProcessTypeUnique: props.name === null ? undefined : true,
      deadlineDays:
        this.props.deadlineDays !== null ? this.props.deadlineDays : 0,
      isDeadlineDaysValid: undefined,
      editingProcessType: props.name !== null,
      allProcessType: [],
      allDocumentType: {},
      allDocumentTypeGetAll: [],
      selectedDocuments:
        props.selectedDocuments === null ? [] : props.selectedDocuments,
      filterValue: "",
      value: props.value ?? "",
      ticketParcels: [],
      isTicketParcel: props?.isTicketParcel ?? false,
      ticketParcel: props.ticketParcel !== null ? props.ticketParcel : "",
      creditCardParcels: [],
      isCreditCard: props?.isCreditCard ?? false,
      creditCardParcel:
        props?.creditCardParcel !== null ? props.creditCardParcel : "",
      payAtTheBeginning: props.payAtTheBeginning ? "Sim" : "Não",
      contractType: "",
      isSigned: "false",
      contractsTypes: {
        "Contrato de Prestação de Serviço - Planejamento":
          "contrato_de_prestacao_de_servico",
        "Orçamento de Serviços de Terceiros":
          "orcamento_de_servicos_de_terceiros",
        "Termo de Representação": "termo_de_representacao",
        "Contrato de Prestação de Servico - Averbacoes":
          "contrato_de_prestacao_de_servico_averbacoes",
        "Contrato de Prestação de Servico - Encaminhamento":
          "contrato_de_prestacao_de_servico_encaminhamento",
        "Contrato de Prestação de Servico - Concessao de pensao por morte":
          "contrato_de_prestacao_de_servico_concessao_de_pensao_por_morte",
        "Contrato de Prestacao de Servico - Tipo 1":
          "contrato_de_prestacao_de_servico_tres_reais",
        "Contrato de Prestacao de Servico - Tipo 2":
          "contrato_de_prestacao_de_servico_quinhentos_e_cinquenta_reais",
      },
      contractsTypesInverse: {
        contrato_de_prestacao_de_servico:
          "Contrato de Prestação de Serviço - Planejamento",
        orcamento_de_servicos_de_terceiros:
          "Orçamento de Serviços de Terceiros",
        termo_de_representacao: "Termo de Representação",
        contrato_de_prestacao_de_servico_averbacoes:
          "Contrato de Prestação de Servico - Averbacoes",
        contrato_de_prestacao_de_servico_encaminhamento:
          "Contrato de Prestação de Servico - Encaminhamento",
        contrato_de_prestacao_de_servico_concessao_de_pensao_por_morte:
          "Contrato de Prestação de Servico - Concessao de pensao por morte",
        contrato_de_prestacao_de_servico_tres_reais:
          "Contrato de Prestacao de Servico - Tipo 1",
        contrato_de_prestacao_de_servico_quinhentos_e_cinquenta_reais:
          "Contrato de Prestacao de Servico - Tipo 2",
      },
      proposalsTypes: {
        "Averbação de Tempo Especial": "proposta_de_averbacao_especial",
        "Averbação de Tempo Pescador": "proposta_de_averbacao_pescador",
        "Averbação de Tempo Rural": "proposta_de_averbacao_rural",
        "Encaminhamento de Aposentadoria":
          "proposta_de_encaminhamento_de_aposentadoria",
        "Planejamento Previdenciário":
          "proposta_de_planejamento_previdenciario",
        "Proposta de concessao de pensao por morte":
          "proposta_de_concessao_de_pensao_por_morte",
        "Proposta de Planejamento Previdenciario - Tipo 1":
          "proposta_de_planejamento_previdenciario_tres_reais",
        "Proposta de Planejamento Previdenciario - Tipo 2":
          "proposta_de_planejamento_previdenciario_quinhentos_e_cinquenta_reais",
      },
      proposalsTypesInverse: {
        proposta_de_averbacao_especial: "Averbação de Tempo Especial",
        proposta_de_averbacao_pescador: "Averbação de Tempo Pescador",
        proposta_de_averbacao_rural: "Averbação de Tempo Rural",
        proposta_de_encaminhamento_de_aposentadoria:
          "Encaminhamento de Aposentadoria",
        proposta_de_planejamento_previdenciario: "Planejamento Previdenciário",
        proposta_de_concessao_de_pensao_por_morte:
          "Proposta de concessao de pensao por morte",
        proposta_de_planejamento_previdenciario_tres_reais:
          "Proposta de Planejamento Previdenciario - Tipo 1",
        proposta_de_planejamento_previdenciario_quinhentos_e_cinquenta_reais:
          "Proposta de Planejamento Previdenciario - Tipo 2",
      },
      validFields: {
        isValueValid: undefined,
        isPayAtTheBeginningValid: undefined,
        isCreditCardValid: undefined,
        isTicketParcelValid: undefined,
      },
      nameContract: null,
      nameProposal: null,
    };
  }

  componentDidMount = () => {
    let ticketParcels = [];
    let creditCardParcels = [];
    for (let i = 1; i <= 10; i++) {
      ticketParcels.push(i);
      creditCardParcels.push(i);
    }
    this.setState({
      ticketParcels,
      creditCardParcels,
    });

    getAllProcessType().then((res) => {
      if (res.ok) {
        this.setState((state) => ({ ...state, allProcessType: res.data }));
      }
    });
    getAllDocumentType().then((res) => {
      if (res.ok) {
        let allDocumentType = {};
        let allDocumentTypeGetAll = res.data;

        for (let i = 0; i < res.data.length; i++) {
          let a = res.data[i];
          allDocumentType[a.name] = a.id;
        }
        this.setState((state) => ({
          ...state,
          allDocumentType,
          allDocumentTypeGetAll,
        }));
      }
    });
    if (this.props.id !== null) {
      const { contractsTypesInverse, proposalsTypesInverse } = this.state;
      let nameContract = null;
      let contractType = "";
      let nameProposal = null;
      let proposalType = "";
      this.props.contracts.forEach((e) => {
        if (
          e.name === "contrato_de_prestacao_de_servico" ||
          e.name === "orcamento_de_servicos_de_terceiros" ||
          e.name === "termo_de_representacao" ||
          e.name === "contrato_de_prestacao_de_servico_averbacoes" ||
          e.name === "contrato_de_prestacao_de_servico_encaminhamento" ||
          e.name ===
          "contrato_de_prestacao_de_servico_concessao_de_pensao_por_morte" ||
          e.name === "contrato_de_prestacao_de_servico_tres_reais" ||
          e.name ===
          "contrato_de_prestacao_de_servico_quinhentos_e_cinquenta_reais"
        ) {
          nameContract = contractsTypesInverse[e.name];
          contractType = contractsTypesInverse[e.name];
        } else {
          nameProposal = proposalsTypesInverse[e.name];
          proposalType = proposalsTypesInverse[e.name];
        }
      });
      this.setState((state) => ({
        ...state,
        nameContract,
        contractType,
        nameProposal,
        proposalType,
      }));
    }
  };

  handleInputChange = (e) => {
    e.persist();
    if (this.state.editingProcessType) {
      this.setState((state) => ({ ...state, [e.target.name]: e.target.value }));
    } else {
      this.setState((state) => ({
        ...state,
        [e.target.name]: e.target.value,
        editingProcessType: false,
      }));
    }
  };

  handleCheckedDocumentType = (e) => {
    const { allDocumentType, selectedDocuments } = this.state;
    e.persist();
    const { name } = e.target;
    let a = selectedDocuments;
    if (!Object.keys(selectedDocuments).includes(name)) {
      a[name] = allDocumentType[name];
    } else {
      delete a[name];
    }
    this.setState((state) => ({ ...state, selectedDocuments: a }));
  };

  handleSelectedContract = (e) => {
    e.persist();
    const { value } = e.target;
    this.setState((state) => ({ ...state, contractType: value }));
  };

  handleSelectedProposal = (e) => {
    e.persist();
    const { value } = e.target;
    this.setState((state) => ({ ...state, proposalType: value }));
  };

  removeSelectedDocument = (name) => {
    const { selectedDocuments } = this.state;
    delete selectedDocuments[name];
    this.setState((state) => ({ ...state, selectedDocuments }));
  };

  isProcessTypeValid = () => {
    const { processType, allProcessType } = this.state;
    let isProcessTypeValid = processType !== "";
    let isProcessTypeUnique = true;
    for (let i = 0; i < allProcessType.length; i++) {
      let b = allProcessType[i];
      if (b.name === processType) {
        isProcessTypeUnique = false;
        break;
      }
    }
    this.setState((state) => ({
      ...state,
      isProcessTypeValid,
      isProcessTypeUnique,
    }));
  };

  getIsFieldValid = (isClassname) => {
    const {
      isProcessTypeValid,
      isProcessTypeUnique,
      processType,
      editingProcessType,
    } = this.state;
    const { name } = this.props;
    if (isProcessTypeUnique === undefined && isProcessTypeValid === undefined) {
      return "";
    } else if (isProcessTypeUnique) {
      if (isProcessTypeValid) {
        if (editingProcessType) {
          return isClassname ? "is-valid" : "";
        } else {
          if (name !== processType) {
            if (processType !== "") {
              return isClassname ? "is-valid" : "";
            } else {
              return isClassname ? "is-invalid" : "O campo não pode ser vazio";
            }
          } else {
            return isClassname
              ? "is-invalid"
              : "Esse tipo de tese está sendo modificado no momento";
          }
        }
      } else {
        return isClassname ? "is-invalid" : "O valor digitado não é válido";
      }
    } else {
      return isClassname ? "is-invalid" : "O valor digitado já está cadastrado";
    }
  };

  isBtnDisabled = () => {
    const {
      processType,
      allProcessType,
      payAtTheBeginning,
      value,
      editingProcessType,
      contractType,
      proposalType,
    } = this.state;

    if (processType.includes("Pré Análise")) {
      return false;
    }
    if (payAtTheBeginning === "") {
      return true;
    }
    if (value === "") {
      return true;
    }
    if (contractType === "") {
      return true;
    }
    if (proposalType === "") {
      return true;
    }
    if (editingProcessType) {
      return false;
    } else {
      if (processType === this.props.name) {
        return true;
      }
    }
    for (let i = 0; i < allProcessType.length; i++) {
      let b = allProcessType[i];
      if (b.name === processType) {
        return true;
      }
    }

    return processType === "";
  };

  isDeadlineDaysValid = () => {
    const { deadlineDays } = this.state;
    let t = /^[+]?\d+$/.test(deadlineDays);
    let isDeadlineDaysValid;
    if (t) {
      isDeadlineDaysValid = deadlineDays !== "";
    } else {
      isDeadlineDaysValid = false;
    }
    this.setState((state) => ({ ...state, isDeadlineDaysValid }));
  };

  isValueValid = () => {
    const { validFields, value } = this.state;
    let isValueValid = value !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isValueValid },
    }));
  };

  isPayAtTheBeginningValid = () => {
    const { validFields, payAtTheBeginning } = this.state;
    let isPayAtTheBeginningValid = payAtTheBeginning !== "";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, isPayAtTheBeginningValid },
    }));
  };

  isParcelValid = (e) => {
    e.persist();
    const { value } = e.target;
    const { validFields } = this.state;
    let name =
      e.target.name === "ticketParcel"
        ? "isTicketParcelValid"
        : "isCreditCardValid";
    this.setState((state) => ({
      ...state,
      validFields: { ...validFields, [name]: value !== "" },
    }));
  };

  submit = () => {
    const {
      processType,
      deadlineDays,
      value,
      payAtTheBeginning,
      isTicketParcel,
      ticketParcel,
      isCreditCard,
      creditCardParcel,
      selectedDocuments,
      contractsTypes,
      proposalsTypes,
      contractType,
      proposalType,
      nameContract,
      nameProposal,
    } = this.state;
    const { id } = this.props;
    let contracts = [];

    contracts.push({
      name: contractsTypes[contractType],
      needSignature: true,
      processTypeId: 0,
    });
    contracts.push({
      name: proposalsTypes[proposalType],
      needSignature: true,
      processTypeId: 0,
    });

    if (id === null) {
      let d = {
        name: processType,
        deadlineDays: deadlineDays,
        value: value,
        payAtTheBeginning: payAtTheBeginning === "Sim" ? true : false,
        useTicket: isTicketParcel,
        ticketParcels: ticketParcel !== "" ? parseInt(ticketParcel) : 0,
        useCreditCard: isCreditCard,
        creditCardParcels:
          creditCardParcel !== "" ? parseInt(creditCardParcel) : 0,
        contracts: contracts,
        documentTypeIdList: Object.values(selectedDocuments),
      };

      putProcessInsertProcessType(d).then((res) => {
        if (res.ok) {
          this.props.onHide(true);
        }
      });
    } else {
      let data = {};
      if (nameContract === null && nameProposal === null) {
        data = {
          id: id,
          name: processType,
          deadlineDays: deadlineDays,
          value: value,
          useTicket: isTicketParcel,
          ticketParcels: ticketParcel !== "" ? parseInt(ticketParcel) : 0,
          useCreditCard: isCreditCard,
          creditCardParcels:
            creditCardParcel !== "" ? parseInt(creditCardParcel) : 0,
          payAtTheBeginning: payAtTheBeginning === "Sim",
          contracts: contracts,
          documentTypeIdList: Object.values(selectedDocuments),
        };
      } else if (
        nameContract !== contractType ||
        nameProposal !== proposalType
      ) {
        data = {
          id: id,
          name: processType,
          deadlineDays: deadlineDays,
          value: value,
          useTicket: isTicketParcel,
          ticketParcels: ticketParcel !== "" ? parseInt(ticketParcel) : 0,
          useCreditCard: isCreditCard,
          creditCardParcels:
            creditCardParcel !== "" ? parseInt(creditCardParcel) : 0,
          payAtTheBeginning: payAtTheBeginning === "Sim",
          contracts: contracts,
          documentTypeIdList: Object.values(selectedDocuments),
        };
      } else {
        data = {
          id: id,
          name: processType,
          deadlineDays: deadlineDays,
          value: value,
          useTicket: isTicketParcel,
          ticketParcels: ticketParcel !== "" ? parseInt(ticketParcel) : 0,
          useCreditCard: isCreditCard,
          creditCardParcels:
            creditCardParcel !== "" ? parseInt(creditCardParcel) : 0,
          payAtTheBeginning: payAtTheBeginning === "Sim",
          contracts: null,
          documentTypeIdList: Object.values(selectedDocuments),
        };
      }
      postProcessUpdateProcessType(data).then((res) => {
        if (res.ok) {
          this.props.onHide(true);
        }
      });
    }
  };

  render() {
    const {
      processType,
      allDocumentType,
      allDocumentTypeGetAll,
      selectedDocuments,
      filterValue,
      deadlineDays,
      isDeadlineDaysValid,
      value,
      payAtTheBeginning,
      contractType,
      contractsTypes,
      proposalType,
      proposalsTypes,
      isTicketParcel,
      ticketParcel,
      ticketParcels,
      isCreditCard,
      creditCardParcel,
      creditCardParcels,
    } = this.state;
    const { isValueValid, isTicketParcelValid, isCreditCardValid } =
      this.state.validFields;
    const { show, onHide, id } = this.props;

    return (
      <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Tipo de tese</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              Nome<Form.Label className="ml-1 is-required">*</Form.Label>
            </Form.Label>
            <Form.Control
              name="processType"
              value={processType}
              onChange={this.handleInputChange}
              onBlur={() => this.isProcessTypeValid()}
              className={this.getIsFieldValid(true)}
              disabled={
                processType === "Planejamento Previdenciário" ||
                processType === "Encaminhamento de Aposentadoria"
              }
            />
            <Form.Text className="is-required">
              {this.getIsFieldValid(false)}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Prazo de entrega
              <Form.Label className="is-required ml-1">*</Form.Label>
            </Form.Label>
            <Form.Control
              name="deadlineDays"
              value={deadlineDays}
              type="number"
              min="1"
              onChange={this.handleInputChange}
              onBlur={() => this.isDeadlineDaysValid()}
              className={
                isDeadlineDaysValid === undefined
                  ? ""
                  : isDeadlineDaysValid
                    ? isDeadlineDaysValid === undefined
                      ? ""
                      : isDeadlineDaysValid
                        ? "is-valid"
                        : "is-invalid"
                    : "is-invalid"
              }
            />
            <Form.Text className="is-required">
              {isDeadlineDaysValid === undefined
                ? ""
                : isDeadlineDaysValid
                  ? ""
                  : deadlineDays !== ""
                    ? "O campo só pode conter números"
                    : "O campo não pode ser vazio"}
            </Form.Text>
          </Form.Group>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                Pagar no início?
                <Form.Label className="ml-1 is-required">*</Form.Label>
              </Form.Label>
              <br />
              <Form.Check
                inline
                type="radio"
                name="payAtTheBeginning"
                value="Sim"
                label="Sim"
                onChange={this.handleInputChange}
                checked={payAtTheBeginning === "Sim"}
              />
              <Form.Check
                inline
                type="radio"
                name="payAtTheBeginning"
                value="Não"
                label="Não"
                onChange={this.handleInputChange}
                checked={payAtTheBeginning === "Não"}
              />
            </Form.Group>
          </Form.Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  Valor<Form.Label className="ml-1 is-required">*</Form.Label>
                </Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>R$</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="number"
                    name="value"
                    value={value}
                    min="1"
                    onChange={this.handleInputChange}
                    onBlur={this.isValueValid}
                    className={
                      isValueValid === undefined
                        ? ""
                        : isValueValid
                          ? "is-valid"
                          : "is-invalid"
                    }
                  />
                </InputGroup>
                <Form.Text className="is-required">
                  {isValueValid === undefined
                    ? ""
                    : isValueValid
                      ? ""
                      : "O campo não pode ser vazio"}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect2">
                <Form.Label>
                  {"Parcela do boleto"}
                  {isTicketParcel ? (
                    <Form.Label className="is-required">*</Form.Label>
                  ) : null}
                </Form.Label>
                <Row>
                  <Col sm={1} md={1} lg={1} xm={1}>
                    <Form.Check
                      type="checkbox"
                      className={"pt-2"}
                      name={"isTicketParcel"}
                      value={isTicketParcel}
                      checked={isTicketParcel}
                      onChange={() =>
                        this.setState({
                          isTicketParcel: !isTicketParcel,
                          ticketParcel: "",
                        })
                      }
                    />
                  </Col>
                  <Col sm={11} md={11} lg={11} xm={11}>
                    <Form.Control
                      as="select"
                      name={"ticketParcel"}
                      value={ticketParcel}
                      onChange={this.handleInputChange}
                      disabled={!isTicketParcel}
                      onBlur={
                        isTicketParcel ? (e) => this.isParcelValid(e) : null
                      }
                      className={
                        isTicketParcelValid === undefined
                          ? ""
                          : isTicketParcelValid
                            ? "is-valid"
                            : "is-invalid"
                      }
                    >
                      <option />
                      {ticketParcels &&
                        ticketParcels.map((value, key) => {
                          return <option key={key}>{value}</option>;
                        })}
                    </Form.Control>
                    <Form.Text className="is-required">
                      {isTicketParcelValid === undefined
                        ? ""
                        : isTicketParcelValid
                          ? ""
                          : "O campo parcela do boleto não deve ser vazio"}
                    </Form.Text>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="exampleForm.ControlSelect3">
                <Form.Label>
                  Parcela do cartão de crédito
                  {isCreditCard ? (
                    <Form.Label className="is-required ">*</Form.Label>
                  ) : null}
                </Form.Label>
                <Row>
                  <Col sm={1} md={1} lg={1} xm={1}>
                    <Form.Check
                      type="checkbox"
                      className={"pt-2"}
                      name={"isCreditCard"}
                      value={isCreditCard}
                      checked={isCreditCard}
                      onChange={() =>
                        this.setState({
                          isCreditCard: !isCreditCard,
                          creditCardParcel: "",
                        })
                      }
                    />
                  </Col>
                  <Col sm={11} md={11} lg={11} xm={11}>
                    <Form.Control
                      as="select"
                      name={"creditCardParcel"}
                      value={creditCardParcel}
                      onChange={this.handleInputChange}
                      disabled={!isCreditCard}
                      onBlur={
                        isCreditCard ? (e) => this.isParcelValid(e) : null
                      }
                      className={
                        isCreditCardValid === undefined
                          ? ""
                          : isCreditCardValid
                            ? "is-valid"
                            : "is-invalid"
                      }
                    >
                      <option />
                      {creditCardParcels &&
                        creditCardParcels.map((value, key) => {
                          return <option key={key}>{value}</option>;
                        })}
                    </Form.Control>
                    <Form.Text className="is-required">
                      {isCreditCardValid === undefined
                        ? ""
                        : isCreditCardValid
                          ? ""
                          : "O campo parcela cartão de credito não deve ser vazio"}
                    </Form.Text>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>
              Tipo de contrato
              <Form.Label className="ml-1 is-required">*</Form.Label>
            </Form.Label>
            <Form.Control
              as="select"
              name="contractType"
              value={contractType}
              onChange={this.handleSelectedContract}
            >
              <option />
              {Object.keys(contractsTypes).map((e, i) => {
                return <option key={i}>{e}</option>;
              })}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>
              Tipo de Proposta
              <Form.Label className="ml-1 is-required">*</Form.Label>
            </Form.Label>
            <Form.Control
              as="select"
              name="proposalType"
              value={proposalType}
              onChange={this.handleSelectedProposal}
            >
              <option />
              {Object.keys(proposalsTypes).map((e, i) => {
                return <option key={i}>{e}</option>;
              })}
            </Form.Control>
          </Form.Group>

          <div className="modal-process-type-table-scrollbar">
            <Table className="process-type-modal-table">
              <thead>
              <tr>
                <th>Tipo de documento</th>
                <th>Subtipo de documento</th>
                <th>Selecionar</th>
              </tr>
              </thead>
              <tbody>
              {Object.keys(allDocumentType)
                .filter(
                  (e, key) =>
                    e.toLowerCase().includes(filterValue.toLowerCase()) ||
                    (allDocumentTypeGetAll[key].subType !== null &&
                      allDocumentTypeGetAll[key].subType
                        .toLowerCase()
                        .includes(filterValue.toLowerCase()))
                )
                .map((item, key) => {
                  return item !== "Outros" ? (
                    <tr key={key}>
                      <td>{item}</td>
                      <td>{allDocumentTypeGetAll[key].subType}</td>
                      <td>
                        <Form.Check
                          type="checkbox"
                          name={item}
                          id={`default-${key}`}
                          checked={Object.keys(selectedDocuments).includes(
                            item
                          )}
                          onChange={this.handleCheckedDocumentType}
                        />
                      </td>
                    </tr>
                  ) : null;
                })}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-0">
          <Row className="w-100 m-0">
            <Button
              variant="primary"
              disabled={this.isBtnDisabled()}
              className="w-100 button-without-rounded"
              onClick={this.submit}
            >
              {id === null ? "Cadastrar" : "Atualizar"}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAlert: (text, alertType, alertPriority) =>
      dispatch(alertActions.addAlert(text, alertType, alertPriority)),
  };
};

export default connect(null, mapDispatchToProps)(ModalProcessType);
